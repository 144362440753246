import { PageRoot, ContentNode, Connection, ContentFrontmatter, Edge } from "../data/content"
import { IndexPageContainer, IndexPageRows } from "../components/indexes"
import React, { Fragment, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useTranslation, Trans } from 'react-i18next';
import { Columns, Rows } from "../components/atoms"
import SEO from '../components/seo';
import { PillarLogo } from '../images/pillars';
import { MobileContentBlock, ContentBlock, HeaderSpacing } from '../components/page';
import { Checkbox, Grid, Label, Select, Box, Flex, Heading } from "theme-ui"
import { TertiaryFeatured, Localised, useLocalisedContent, TertiaryFeaturedPaged } from '../components/featured';
import { Chip } from '../components/atoms'
import { keys, groupBy } from 'lodash'
import { useTextDirection } from "../i18n";
import { getLanguageName } from "../data/languages";
import { colourMidpoint } from "../data/methods";
import { PillarFiltersStyling, FilterStyling } from "../components/joinform/elements";
import languages from "../data/languages";
import { ParterOrganisationString, PartnerOrganisationCard } from "../components/misc";


const WireIndexPage: PageRoot<{ content: Connection<ContentNode>, partners: Connection<ContentNode> }> = ({
  data: { content, partners }, location: { pathname }
}) => {
  const { i18n: { language }, t } = useTranslation()
  const dir = useTextDirection(language)

  const unintrusiveColour = "#6a6a6a"

  const fadedColour = colourMidpoint("#CCF2E7", "#00bf89")

  const groupedArticlesByTopic = groupBy(content.edges, (article) => {
    return article.node.frontmatter.topic?.id
  })
  const groupedArticlesByRegion = groupBy(content.edges, (article) => {
    return article.node.frontmatter.region?.id
  })
  const groupedArticlesBySource = groupBy(content.edges, (article) => {
    return article.node.frontmatter.source?.id
  })

  let availableLocales: string[] = []

  content.edges.forEach(function (article) {
    article.node.fields.localePaths?.forEach(function (localePath) {
      if (!availableLocales.includes(localePath.locale)) {
        availableLocales.push(localePath.locale)
      }
    })
  })

  availableLocales.sort()

  let groupedArticlesByLanguageDictionary: { [id: string]: Edge<ContentNode<ContentFrontmatter>>[]; } = {}

  availableLocales.forEach(locale => {
    groupedArticlesByLanguageDictionary[locale] = []
  })

  content.edges.forEach(article => {
    availableLocales.forEach(locale => {
      if (article.node.fields.localePaths?.map(localePath => localePath.locale).includes(locale)) {
        groupedArticlesByLanguageDictionary[locale].push(article)
      }
    })
  })

  const groupedArticlesByLanguage = groupedArticlesByLanguageDictionary

  //const groupedArticlesByLanguage = groupBy(content.edges, (article) => {
  //  return article.node.fields.localePaths?.map(localePath => localePath.locale).some(locale => availableLocales.includes(locale))
  //})

  const [state, setState] = useState({
    filters: {
      selectedTopicId: "",
      selectedRegionId: "",
      selectedSourceId: "",
      selectedLocale: ""
    }
  })

  const handleTopicChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedTopicId: event.target.value }
    setState({ filters })
  }

  const handleRegionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedRegionId: event.target.value }
    setState({ filters })
  }

  const handleLanguageChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedLocale: event.target.value }
    setState({ filters })
  }

  const handleSourceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const filters = { ...state.filters, selectedSourceId: event.target.value }
    setState({ filters })
  }

  const showArticle = (node: ContentNode) => {
    const selectedSourceId = state.filters.selectedSourceId
    const selectedTopicId = state.filters.selectedTopicId
    const selectedRegionId = state.filters.selectedRegionId
    const selectedLocale = state.filters.selectedLocale

    let valid = true

    if (selectedTopicId?.length) {
      if (node.frontmatter.topic?.id != selectedTopicId) {
        valid = false
      } else if (!node.frontmatter.topic) {
        valid = false
      }
    }

    if (selectedRegionId?.length) {
      if (node.frontmatter.region?.id != selectedRegionId) {
        valid = false
      } else if (!node.frontmatter.region) {
        valid = false
      }
    }

    if (selectedLocale?.length) {
      if (!node.fields.localePaths?.map(localePath => localePath.locale).includes(selectedLocale)) {
        valid = false
      } else if (node.fields.localePaths === undefined) {
        valid = false
      }
    }

    if (selectedSourceId?.length) {
      if (node.frontmatter.source?.id != selectedSourceId) {
        valid = false
      } else if (!node.frontmatter.source) {
        valid = false
      }
    }

    return valid
  }

  const toggleFilters = () => {
    try {
      const filterContainer = document.getElementById("filterContainer")

      if (filterContainer?.classList.contains("openFilter")) {
        filterContainer.classList.remove("openFilter")
      } else {
        filterContainer?.classList.add("openFilter")
      }
    } catch (e) {

    }
  }

  const filteredContent = content.edges.filter((value) => {
    return showArticle(value.node);
  });

  return (
    <IndexPageContainer
      title={t(`Wire`)}
      color="wireHex"
      bg="wireHexLight"
      heading={
        <Fragment sx={{ marginBottom: "8px" }}>
          <p><Trans>We are a wire service to the world's progressive forces.</Trans></p>
          <p><Trans>Every day, the Wire translates stories, essays, and statements from Progressive International members and partner publications.</Trans></p>
          <p><Trans>Our aim is to provide a diversity of perspectives on international issues. Publication in the Wire is not an endorsement from the Progressive International.</Trans></p>
        </Fragment>
      }
      icon={
        <PillarLogo sx={{ color: "wireHex", size: [40, 70, 80, 100] }} type={'wire'} />
      }
      isRtl={dir.isRtl}

      extra={
        <Flex id="filterContainer" sx={PillarFiltersStyling("wire", fadedColour, unintrusiveColour, dir)}>
          <Label>
            FILTERS
            <div onClick={toggleFilters}><Select disabled></Select></div>
          </Label>
          <Box pr={["0px", "6px", "42px"]} sx={{ flex: ['100%', '25%', '25%'] }}>
            <Select
              name='topic'
              onChange={handleTopicChange}
              sx={FilterStyling(state.filters.selectedTopicId, "wire", fadedColour, unintrusiveColour, dir, false)}
            >
              <option value="">{t('Topic')}</option>
              {keys(groupedArticlesByTopic).map((id: string) => (
                id != 'undefined' && <option value={id}>
                  {useLocalisedContent(groupedArticlesByTopic[id][0].node.frontmatter.topic)?.title}
                </option>
              ))}
            </Select>
          </Box>
          <Box pl={["0px", "2px", "14px"]} pr={["0px", "4px", "28px"]} sx={{ direction: dir.isRtl ? 'rtl' : 'ltr', flex: ['100%', '25%', '25%'] }}>
            <Select
              name='region'
              onChange={handleRegionChange}
              sx={FilterStyling(state.filters.selectedRegionId, "wire", fadedColour, unintrusiveColour, dir, false)}
            >
              <option value="">{t('Region')}</option>
              {keys(groupedArticlesByRegion).map((id: string) => (
                id != 'undefined' && <option value={id}>
                  {useLocalisedContent(groupedArticlesByRegion[id][0].node.frontmatter.region)?.title}
                </option>
              ))}
            </Select>
          </Box>
          <Box pl={["0px", "4px", "28px"]} pr={["0px", "2px", "14px"]} sx={{ direction: dir.isRtl ? 'rtl' : 'ltr', flex: ['100%', '25%', '25%'] }}>
            <Select
              name='language'
              onChange={handleLanguageChange}
              sx={FilterStyling(state.filters.selectedLocale, "wire", fadedColour, unintrusiveColour, dir, false)}
            >
              <option value="">{t('Language')}</option>
              {keys(groupedArticlesByLanguage).map((id: string) => (
                id != 'undefined' && <option value={id}>
                  {languages.find(l => l.isoName == id)?.nativeName}
                </option>
              ))}
            </Select>
          </Box>
          <Box pl={["0px", "6px", "42px"]} sx={{ direction: dir.isRtl ? 'rtl' : 'ltr', flex: ['100%', '25%', '25%'] }}>
            <Select
              name='source'
              onChange={handleSourceChange}
              sx={FilterStyling(state.filters.selectedSourceId, "wire", fadedColour, unintrusiveColour, dir, true)}
            >
              <option value="">{t('Source')}</option>
              {keys(groupedArticlesBySource).map((id: string) => (
                id != 'undefined' && <option value={id}>
                  {groupedArticlesBySource[id][0].node.frontmatter.source?.frontmatter.title}
                </option>
              ))}
            </Select>
          </Box>
        </Flex >
      }
    >
      <SEO
        path={pathname}
        title={'Wire'}
        description={'The Wire transmits breaking stories and critical perspectives from a coalition of progressive media around the world.'}
      />

      <ContentBlock>
        <TertiaryFeaturedPaged edges={filteredContent} />
      </ContentBlock>

      <Box sx={{ bg: "white" }}>
        <ContentBlock>
          <Heading variant='display' sx={{ color: "wireHex", fontSize: ["1em", "1.25em", "1.25em"], mb: "0.5em" }}>Partners</Heading>
          <Box sx={{ fontFamily: '"IBM Plex Mono", Menlo, monospace', color: "#6a6a6a", lineHeight: "1.45em", fontSize: "0.875em" }}>
            {partners.edges.map(({ node }, index) => (
              <span>
                {index != 0 && <span>   &#47;   </span>}
                <ParterOrganisationString node={node} />
              </span>
            ))}
          </Box>
        </ContentBlock>
      </Box>
    </IndexPageContainer >
  )
}

export const pageQuery = graphql`
  query WireIndexPage {
    content: allMarkdownRemark(
      filter: { fields: { type: { eq: "wire-articles" } } }
      sort: { fields: [frontmatter___publishDate], order: DESC }
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
    partners: allMarkdownRemark(
      filter: {
        fields: { type: { eq: "partner-orgs" } }
        frontmatter: {
          title: { regex: "/^((?!Progressive International).)*$/" }
          topic: { frontmatter: { contentId: { eq: "854663c1-ee6a-4735-a211-945e7e435ed5" } } }
        }
      }
      sort: { fields: [frontmatter___title], order: ASC }
    ) {
      edges {
        node {
          ...ListFragment
        }
      }
    }
  }
`

export default WireIndexPage