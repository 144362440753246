/** @jsx jsx */

import { Box, jsx } from "theme-ui";
import { ContentNode } from "../data/content";
import { ImageCard } from "./atoms";
import { Dot } from "./content-page";
import { useLocalisedContent } from "./featured";
import GatsbyImage from "gatsby-image/withIEPolyfill";
import { navigate } from "gatsby";
import { Link } from "./nav";

export const PartnerOrganisationCard: React.FC<{ node: ContentNode }> = ({ node }) => {
  const localisedContent = useLocalisedContent(node);

  return (
    <Box>
      <Box onClick={() => navigate(localisedContent.path)} sx={{
        height: [100, 125, 175],
        cursor: "pointer"
      }}>
        {node.frontmatter.featuredImage && node.frontmatter.featuredImage.childImageSharp ? (
          <GatsbyImage
            objectFit="contain"
            objectPosition="center"
            sx={{
              width: "100%",
              height: "100%",
              maxWidth: "100% !important",
              mixBlendMode: "multiply",
              filter: "grayscale(1)",
              ':hover': {
                filter: "grayscale(0)"
              }
            }}
            {...node.frontmatter.featuredImage.childImageSharp}
          />
        ) : (
          <Dot />
        )}
      </Box>
    </Box>
  )
}

export const ParterOrganisationString: React.FC<{ node: ContentNode }> = ({ node, ...props }) => {
  const localisedContent = useLocalisedContent(node);

  return (
    <Link {...props} to={localisedContent.path} sx={{ whiteSpace: "nowrap" }}>
      {localisedContent.title}
    </Link>
  )
}